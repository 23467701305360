<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <Logo />

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-start p-0"
      >
        <div
          class="w-100 h-100 d-lg-flex align-items-start justify-content-center p-0"
          :style="'background-image: url(' +
            imgUrl +
            ');background-repeat:no-repeat;background-size:cover;background-position: center;'
            "
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Καλωσήρθατε στην {{ $t(appName) }}!
          </b-card-title>
          <b-card-text class="mb-2">
            Παρακαλώ συνδεθείτε για να ξεκινήσετε
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            v-slot="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Κωδικός</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Ξεχάσατε τον κωδικό σας;</small>
                  </b-link>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Να με θυμάσαι
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Σύνδεση
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>
              Επιθυμείτε να γίνετε μέλος της Ελληνικής Καρδιολογικής Εταιρείας;
            </span>
            <br />
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;Δημιουργήστε λογαριασμό</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { formUtils } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Logo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Logo,
  },
  mixins: [formUtils],
  data() {
    return {
      status: '',
      password: this.$isDevelopment ? 'K+150FqW2=K' : '',
      userEmail: this.$isDevelopment ? 'info@inspire-web.gr' : '',
      sideImg: require('@/assets/images/pages/hcs-profile.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    appName() {
      const { appName } = $themeConfig.app
      return appName
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch('app-common/jwtLogin', {
              username: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              const userData = response.data
              useJwt.setToken(userData.token)
              useJwt.setRefreshToken(userData.token)
              delete userData.token
              delete userData.password
              localStorage.setItem('userData', JSON.stringify(userData))
              window.location = getHomeRouteForLoggedInUser()
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$i18n.t('Invalid Username or Password'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
