// We haven't added icon's computed property because it makes this mixin coupled with UI
export const formUtils = {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    formatRightsForTable(data) {
      const rights = []
      data.forEach(item => {
        const rightGroup = { subgroups: [] }
        rightGroup.title = item.title
        item.subgroups.forEach(subItem => {
          const subGroup = {}
          subGroup.title = subItem.title
          subItem.rights.forEach((right, index) => {
            subGroup[right.title] = { id: right.id, value: right.hasRight }
            // if (index === subItem.rights.length - 1) {
            //   subGroup.canAssign = { id: right.id, value: right.canAssign }
            // }
          })
          rightGroup.subgroups.push(subGroup)
        })
        rights.push(rightGroup)
      })
      return rights
    },
  },
}

export const _ = null
